import {
  memo, useState, useCallback, useMemo,
} from 'react';
import {
  WidgetContainer,
  useTranslations,
  EditableContainer,
  useSnackbar,
  DetailsElements,
} from '@uniqkey-frontend/shared-app';
import { GetOrganizationByIdResponse } from '@uniqkey-backend-partner/api-client';
import { useUser } from '../../../../../../contexts/UserContext';
import { useUpdateOrganization } from '../../../../../../hooks/reactQuery';
import ACLEnum from '../../../../../../enums/ACLEnum';
import EditOrganizationDetailsModal, {
  IEditOrganizationDetailsModalReturnValue,
} from '../EditOrganizationDetailsModal';

interface IOrganizationDetailsWidgetProps {
  organizationId: string;
  organization?: GetOrganizationByIdResponse;
  isLoading: boolean;
  isError: boolean;
}

const MIN_CONTAINER_HEIGHT = 305;

const OrganizationDetailsWidget = (props: IOrganizationDetailsWidgetProps) => {
  const {
    organizationId, organization, isLoading, isError,
  } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();
  const { userCan } = useUser();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const handleEditModalOpen = useCallback(() => setIsEditModalOpen(true), []);
  const handleEditModalClose = useCallback(() => setIsEditModalOpen(false), []);

  const {
    partnerId,
    deletedAt,
    hardDeletionAt,
  } = organization ?? {} as GetOrganizationByIdResponse;

  const isInRetentionPeriod = !!(deletedAt && hardDeletionAt);

  const { mutate, isLoading: isUpdateOrganizationLoading } = useUpdateOrganization({
    organizationId,
    partnerId,
    useOptimisticUpdates: true,
  });

  const handleEditOrganizationDetails = useCallback((
    value: IEditOrganizationDetailsModalReturnValue,
  ) => {
    mutate(
      value,
      {
        onError: () => showError({ text: t('common.somethingWentWrong') }),
        onSuccess: () => {
          showSuccess({ text: t('organizationPage.toast.detailsUpdated') });
          handleEditModalClose();
        },
      },
    );
  }, [mutate, handleEditModalClose, showError, showSuccess, t]);

  const elements = useMemo(() => {
    const {
      name,
      address,
      vat,
      zip,
      billingEmail,
      country,
      phone,
      city,
      committedLicenseCount,
      externalId,
    } = organization ?? {};
    return {
      name,
      address,
      vat,
      zip,
      billingEmail,
      country,
      phone,
      city,
      committedLicenseCount,
      externalId,
    };
  }, [organization]);

  if (isError) {
    return null;
  }

  return (
    <WidgetContainer
      container
      /*
       While the data is loading, we need to have padding here so the skeleton doesn't
       take the full widget size.
       When the data is loaded - we set the proper padding in the EditableContainer below.
      */
      p={isLoading ? 3 : 0}
      pb={3}
      isLoading={isLoading}
      minHeight={MIN_CONTAINER_HEIGHT}
      withShadow
    >
      <EditableContainer
        container
        item
        p={isLoading ? 0 : 3}
        rowGap={2}
        minHeight={MIN_CONTAINER_HEIGHT}
        onClick={handleEditModalOpen}
        disabled={!userCan(ACLEnum.OrganizationEdit) || isInRetentionPeriod}
      >
        <DetailsElements
          hidden={!organization}
          elements={elements}
          translationKey="organizationDetailsWidget"
          t={t}
        />
      </EditableContainer>
      {isEditModalOpen && (
        <EditOrganizationDetailsModal
          isOpen={isEditModalOpen}
          isLoading={isUpdateOrganizationLoading}
          onSubmit={handleEditOrganizationDetails}
          onClose={handleEditModalClose}
          organization={organization!}
        />
      )}
    </WidgetContainer>
  );
};

export default memo(OrganizationDetailsWidget);
