import {
  ScrollablePanelContent,
  Grid,
  Spinner,
  RenderIf,
} from '@uniqkey-frontend/shared-app';
import { memo } from 'react';
import DeleteOrganizationWidget from './components/DeleteOrganizationWidget';
import {
  useGetOrganizationSettingsInfo,
  useGetOrganizationById,
} from '../../../../hooks/reactQuery';
import DeletionActionDetailsWidget from './components/DeletionActionDetailsWidget';
import MoveDetailsInfoWidget from './components/MoveDetailsInfoWidget';
import RestoreOrganizationWidget from './components/RestoreOrganizationWidget';

interface IOrganizationSettingsTabProps {
  organizationId: string;
}

const OrganizationSettingsTab = (props: IOrganizationSettingsTabProps) => {
  const { organizationId } = props;

  const {
    data: settingsInfo,
    isLoading: isSettingsInfoLoading,
    error: settingsInfoError,
  } = useGetOrganizationSettingsInfo({ organizationId });

  const {
    data: organization,
    isLoading: isOrganizationLoading,
    error: organizationError,
  } = useGetOrganizationById({ organizationId });

  if (!settingsInfo || !organization) {
    return null;
  }

  const {
    archivedByPartnerUserName,
    archivedByPartnerUserEmail,
    movedFromPartnerName,
    movedFromContactPersonName,
    movedFromContactPersonEmail,
    movedFromContactPersonPhone,
  } = settingsInfo;

  const {
    name: organizationName,
    partnerId: organizationPartnerId,
    deletedAt,
    hardDeletionAt,
  } = organization;

  const isInRetentionPeriod = !!(deletedAt && hardDeletionAt);

  const showMoveDetailsInfoWidget = movedFromPartnerName
    || movedFromContactPersonName
    || movedFromContactPersonEmail
    || movedFromContactPersonPhone;

  if (isSettingsInfoLoading || isOrganizationLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center" flex={1}>
        <Spinner size="40px" />
      </Grid>
    );
  }

  if (settingsInfoError || organizationError) {
    return null;
  }

  return (
    <ScrollablePanelContent p={3} pt={1}>
      <Grid container spacing={2}>
        <Grid container item xs={6} rowGap={3}>
          {isInRetentionPeriod ? (
            <RestoreOrganizationWidget
              organizationId={organizationId}
              organizationName={organizationName}
              organizationPartnerId={organizationPartnerId}
              isOrganizationLoading={isOrganizationLoading}
              deletedAt={deletedAt}
              hardDeletionAt={hardDeletionAt}
            />
          ) : (
            <DeleteOrganizationWidget
              organizationId={organizationId}
              organizationName={organizationName}
              organizationPartnerId={organizationPartnerId}
              isOrganizationLoading={isOrganizationLoading}
            />
          )}
          <RenderIf condition={!!archivedByPartnerUserName || !!archivedByPartnerUserEmail}>
            <DeletionActionDetailsWidget
              archivedByPartnerUserName={archivedByPartnerUserName}
              archivedByPartnerUserEmail={archivedByPartnerUserEmail}
            />
          </RenderIf>
        </Grid>
        <RenderIf condition={!!showMoveDetailsInfoWidget}>
          <Grid item xs={6}>
            <MoveDetailsInfoWidget
              movedFromPartnerName={movedFromPartnerName}
              movedFromContactPersonName={movedFromContactPersonName}
              movedFromContactPersonEmail={movedFromContactPersonEmail}
              movedFromContactPersonPhone={movedFromContactPersonPhone}
            />
          </Grid>
        </RenderIf>
      </Grid>
    </ScrollablePanelContent>
  );
};

export default memo(OrganizationSettingsTab);
